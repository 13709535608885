"use client";

import { GTMParams } from "@next/third-parties/dist/types/google";
import { useEffect, useState } from "react";


const GTM_ID = process.env.GTM_ID;

export default function Analytics() {
  const [GoogleTagManager, setGoogleTagManager] = useState<null | ((props: GTMParams) => JSX.Element)>(null);

  useEffect(() => {
    if (!GTM_ID) {
      return;
    }

    import("@next/third-parties/google").then((module) => {
      setGoogleTagManager(() => module.GoogleTagManager);
    });
  }, []);

  if (!GoogleTagManager) {
    return null;
  }

  return <GoogleTagManager gtmId={GTM_ID || ""} />;
}
