import { createTheme } from "@mui/material/styles"
import { sharedTheme } from "ui"
import { GlobalStyle } from "./GlobalStyle"
import colors from "./colors"
const customTheme = createTheme({
  ...sharedTheme,
  components: {
    ...sharedTheme.components,
    MuiCssBaseline: {
      styleOverrides: GlobalStyle,
    },
  },
})

export const theme = {
  ...customTheme,
  colors,
}

export type Theme = typeof theme
