import { css } from "@emotion/react";

export const GlobalStyle = css`

  :root {
    direction: rtl;
    font-family: var(--rubik);

  }
  body {
    font-family: var(--rubik);
    overscroll-behavior-x: none;
    next-route-announcer{
      right:0;
      width:1px;
    }

  }
  .gm-style-iw.gm-style-iw-c {
    display: flex;
    flex-direction: row-reverse;
  }
  next-route-announcer{
    display:none;
  }
`
